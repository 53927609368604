.PresenterStart {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  
  .container {
    border: solid 2px black;
    padding: 2em 3em;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titlebar {
      display: flex;
      flex: row;
      margin-bottom:2em;
      padding:0.75em 0;
    }

    .select-presentation {
      font-size: 1.25em;
      margin-bottom: 2em;
      padding: 0.1em;
    }

    .start {
      font-size: 1.25em;
      padding: 0.25em 2em;
    }
  }

}