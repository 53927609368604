.Sidebar {

  display: flex;
  flex-direction: column;
  margin-right: 1em;

  .item {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0.5em;
    min-width: 3em;
    height: 3em;
    margin-bottom: 0.5em;

    color: black;
    background-color: white;
    border-radius: 0.35em;

    &:hover {
      background-color: #f0f0ff;
    }

    cursor: pointer;

    label {
      font-size: 0.6em;
      white-space: nowrap;
      margin-top: 0.5em;
      cursor: inherit;
    }

  }

}