.VR {

  opacity: 1;
  transition: opacity 1s;

  width: 100%;
  height: 100%;
  
  &.is-presenter {
    width: 960px;
    height: 480px;
    margin: 0 1em;
  }

  &.fadeout {
    opacity: 0;
  }
  
}


.vr-container {
  width: 960px;
  height: 480px;
  margin: 0 1em;
}