.PublicURL {
  display:flex;
  flex-direction: row;

  .url {
    border: none;
    min-width: 30em;
    text-align: right;
    margin-right: 0.5em;
    outline: none;
  }

  .copy {
    cursor: pointer;
    width: 1.25em;
  }

  .hidden {
    position: absolute;
    left: 0;
    top: -100%;
  }

} 